<template>
  <Form class="DynamicForm" v-slot="{ errors }">
    <div v-for="field in fields" :key="field.name" class="field">
      <label
        class="label is-capitalized"
        :class="{ 'has-text-light': isLight }"
        >{{ field.label }}</label
      >
      <p
        :class="[
          'control',
          {
            'is-loading': state.loading,
            'has-icons-left': field.iconLeft,
            'has-icons-right': field.iconRight,
          },
        ]"
      >
        <Field
          class="input"
          :disabled="state.loading || state.loaded"
          :placeholder="field.label + '...'"
          :type="field.type || 'text'"
          :name="field.name"
          :as="field.as || 'input'"
          :rules="field.rules"
        />

        <span class="icon is-small is-left" v-if="field.iconLeft">
          <i :class="['fas', field.iconLeft]"></i>
        </span>
        <span class="icon is-small is-right" v-if="field.iconRight">
          <i :class="['fas', field.iconRight]"></i>
        </span>
      </p>
      <p
        class="help"
        :class="[isLight ? 'is-light' : 'is-danger']"
        v-if="errors[field.name]"
      >
        {{ errors[field.name] }}
      </p>
    </div>
    <div class="field mt-5">
      <div class="control">
        <button
          class="button is-fullwidth is-rounded"
          :class="[
            isLight ? 'is-light' : 'is-primary',
            {
              'is-loading': state.loading,
              'is-success': state.loaded && state.success,
              'is-danger': state.loaded && !state.success,
            },
          ]"
          :disabled="state.loading || state.loaded"
        >
          <span class="icon is-small" v-if="state.loaded">
            <i class="fas fa-check" v-if="state.success"></i>
            <i class="fas fa-times" v-else></i>
          </span>
          <span class="has-text-weight-medium" v-else>
            {{ submitText }}
          </span>
        </button>
      </div>
    </div>
  </Form>
</template>

<script>
import { Field, Form, defineRule } from "vee-validate";
import AllRules from "@vee-validate/rules";

Object.keys(AllRules).forEach((rule) => {
  defineRule(rule, AllRules[rule]);
});
export default {
  components: {
    Field,
    Form,
  },
  props: {
    submitText: {
      required: false,
      type: String,
      default: () => "Submit",
    },
    isLight: {
      required: false,
      type: Boolean,
      default: () => false,
    },
    fields: {
      required: true,
      type: Array,
    },
    state: {
      required: false,
      default: () => ({
        loading: false,
        loaded: false,
        success: true,
      }),
    },
  },
  data() {
    return {
      t: this.$store.getters["Translations/getTranslations"](
          this.$options.name
      ),
    }
  },
};
</script>
