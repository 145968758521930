<template>
  <div id="login" class="columns">
    <div class="column with-form is-6 is-12-touch">
      <div class="hero is-fullheight">
        <div class="hero-body">
          <div class="container">
            <div class="columns">
              <div class="column is-8-widescreen is-offset-2-widescreen">
                <div class="is-flex is-align-items-center mb-5 is-size-1">
                  <span class="icon is-large has-text-primary mr-3">
                    <i class="fas fa-arrow-circle-right"></i>
                  </span>
                  <h2 class="title has-text-primary">{{ t("Login") }}</h2>
                </div>
                <Form
                  :fields="[
                    {
                      name: 'email',
                      label: 'email',
                      rules: {
                        required: true,
                        email: true,
                      },
                    },
                    {
                      name: 'password',
                      label: 'password',
                      type: 'password',
                      rules: {
                        required: true,
                      },
                    },
                  ]"
                  submitText="Login"
                  :state="formState"
                  @submit="customLogin"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="column is-6 with-graphic is-hidden-touch">
      <div
        class="
          container
          is-flex is-align-items-center is-justify-content-center
        "
      >
        <div class="is-inline-block">
          <img
            src="../../assets/avatar.png"
            class="is-block"
            alt="loginGraphic"
          />
          <!-- <p
            class="
              text
              is-block
              has-text-centered
              mt-5
              is-size-4
              has-text-white has-text-weight-bold
            "
          >
            {{ t('"solving problems"') }}
          </p> -->
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#login {
  min-height: 100%;
  .column {
    &.with-form {
      padding-bottom: 0;
      .hero {
        .hero-body {
          .box {
            width: 100%;
          }
        }
      }
    }
    &.with-graphic {
      background: rgba(255, 0, 0, 0.26);
      .container {
        height: 100%;
        div {
          width: 80%;
          max-width: 600px;
          img {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>

<script>
import Form from "@/components/Dynamic/Form.vue";
import { mapActions } from "vuex";

export default {
  name: "Login",
  components: {
    Form
  },
  data() {
    return {
      formState: {
        loading: false,
        loaded: false,
        success: true,
        isMobile: true,
      },
      t: this.$store.getters["Translations/getTranslations"](this.$options.name)
    };
  },
  methods: {
    ...mapActions(["login"]),
    customLogin(user) {
      this.formState.loading = true;
      this.login(user)
        .then(() => {
          this.formState.loading = false;
          this.formState.loaded = true;
          this.formState.success = true;
          if(!this.checkMobile(user)) {
            setTimeout(() => {
              this.formState.loaded = true;
              this.$router.push("/dash");
            }, 700);
          } else {
            setTimeout(() => {
              this.formState.loaded = true;
              this.$router.push("/");
            }, 700);
          }
        })
        .catch(() => {
          this.formState.loading = false;
          this.formState.loaded = true;
          this.formState.success = false;
          setTimeout(() => (this.formState.loaded = false), 700);
        });
    },
    checkMobile(user) {
      return window.innerWidth <= 700 && (
          user.email === 'info@marcocorver.nl'
          || user.email === 'maurice@consultinvest.nl'
          || user.email === 'jim.harders@gmail.com'

      );
    },
  }
};
</script>
